import React from 'react';
import { Helmet } from 'react-helmet';

const FendtPage = ({ data, location }) => {
  if (typeof window !== 'undefined') {
    window.location = 'https://fendt.variowelt.at';
  }
  return (
    <>
      <iframe
        width="100%"
        height="100%"
        style={{ height: '100vh', width: '100vw' }}
        frameBorder="0"
        src="https://fendt.preview.mangomedia.at"
      />
      <Helmet>
        <style>{`body { margin: 0px !important; padding: 0px !important}`}</style>
      </Helmet>
    </>
  );
};

export default FendtPage;
